<template>
    <div class="container">
        <h1
            class="mt-5 wishlist-title"
            style="font-size: calc(100% + 1vw + 1vh);font-weight: bold"
        >
            Wishlist
        </h1>
        <div
            class="d-flex flex-wrap align-items-start justify-content-center justify-content-md-start mt-4 pb-4 pt-2"
            style="gap: 1rem"
            v-if="wishlists && wishlists.length > 0"
        >
            <div class="card" v-for="product in wishlists" :key="product.id">
                <div>
                    <img
                        :src="product.photo.thumbnail_url"
                        :alt="product.title"
                        class="card-img-top"
                    />
                    <font-awesome-icon
                        @click="toggleWish(product.id)"
                        :icon="['fas', 'heart']"
                        class="icon position-absolute rounded-circle bg-light wish-list"
                        :id="`active-wish-${product.id}`"
                    />
                    <div class="my-2 mx-2" @click="toDetailPage(product.slug)">
                        <p class="arrival-body" style="font-size: 14px;">
                            {{ product.title }}
                        </p>
                        <div class="d-flex flex-column flex-lg-row gap-1">
                            <p class="arrival-body fw-bold">
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0].final_price,
                                    )
                                }}
                                MMK
                            </p>
                            <p
                                class="sold-out text-center"
                                v-if="soldOutedProducts.includes(product.id)"
                            >
                                Out of Stock
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center my-5">
            You have no wishlists at the moment
        </div>
        <!-- <div class="d-flex justify-content-center align-items-center">
            <main-button name="Explore All Jewellery" />
        </div> -->
        <footer-section />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Slide from '@/utils/Slide';
import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';
// import Button from '../reusable/Button';
import Footer from '../home/Footer';

// apollo queries

export default {
    mixins: [Slide, CommaPrice, AddToWishList],
    components: {
        // 'main-button': Button,
        'footer-section': Footer,
    },
    data() {
        return {
            soldOutedProducts: [],
            wishListColor: false,
            show: [],
        };
    },
    created() {
        this.getWishlists();
    },

    computed: {
        ...mapGetters({
            wishlists: 'wishlist/getWishlists',
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },

    methods: {
        slideInit() {
            // preview, space, direction, loop
            this.initSlide('.arrival-area', 3, 10, 'horizontal');
        },
        toDetailPage(slug) {
            this.$router.push({
                name: 'ProductVariantDetail',
                params: {
                    slug: slug,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    width: 10rem;
    // margin: 0 0.5rem 0.5rem 0;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    .icon {
        cursor: pointer;
        color: rgb(220, 217, 217);
        padding: 6px;
        width: 1.8rem;
        height: 1.8rem;
        top: 10px;
        right: 0px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 10rem;
    }
    .arrival-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .sold-out {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 50px;
        background: transparent
            linear-gradient(102deg, #e43225 0%, #ff5400 100%) 0% 0% no-repeat
            padding-box;
    }
}
.wishlist-title {
    text-align: center;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .card {
        width: 15rem;
        img {
            height: 12rem;
        }
    }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .wishlist-title {
        text-align: left;
    }
}
</style>
