<template>
    <wish-list />
</template>

<script>
import WishList from '../../components/profile/WishList';

export default {
    components: {
        'wish-list': WishList,
    },
};
</script>
