import Swiper from 'swiper/bundle';

const Slide = {
    methods: {
        initSlide(container, preview, space, direction, loop = true) {
            const mySwiper = new Swiper(container, {
                preloadImages: false,
                lazy: true,
                direction: direction,
                loop: loop,
                centerInsufficientSlides: true,

                // Default parameters
                slidesPerView: preview,
                spaceBetween: space,
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1080: {
                        slidesPerView: preview,
                        spaceBetween: 10,
                    },
                },
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                // scrollbar: {
                //     el: '.swiper-scrollbar',
                // },
            });
            mySwiper.update();
        },
    },
};

export default Slide;
